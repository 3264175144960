import { callApi, callVoidApi } from "./Utils";

export const getNextMaintenanceDate = async (accessToken) => {
    const result = await callApi('MaintenanceDate/GetNextMaintenanceDate', 'GET', accessToken);
    return result;
}

export const getMaintenanceDates = async (accessToken) => {
    const result = await callApi('MaintenanceDate/GetMaintenanceDates', 'GET', accessToken);
    return result;
}

export const addMaintenanceDate = async (accessToken, dateString, hoursBeforeDateToNotify, isLocking) => {
    const body = {
        dateString: dateString,
        hoursBeforeDateToNotify: hoursBeforeDateToNotify,
        isLocking: isLocking
    }
    await callVoidApi('MaintenanceDate/AddMaintenanceDate', 'POST', accessToken, body);
}

export const deleteMaintenanceDate = async (accessToken, id) => {
    await callVoidApi(`MaintenanceDate/DeleteMaintenanceDate?id=${id}`, 'DELETE', accessToken);
}