import { callApi, callVoidApi } from "./Utils";

export const getUser = async (accessToken) => {
    const user = await callApi('user', 'GET', accessToken);
    return user;
}

export const setLearnRate = async (accessToken, learnRate) => {
    await callVoidApi(`user/SetLearnRate?learnRate=${learnRate}`, 'PUT', accessToken);
}

export const setNewWordCount = async (accessToken, newWordCount) => {
    await callVoidApi(`user/SetNewWordCount?newWordCount=${newWordCount}`, 'PUT', accessToken);
}

export const getLearnedCount = async (accessToken) => {
    return await callApi('user/GetLearnedCount', 'GET', accessToken);
}

export const getOverallAccuracy = async (accessToken) => {
    return await callApi('user/GetOverallAccuracy', 'GET', accessToken);
}

export const setIncludeProperNames = async (accessToken, includeProperNames) => {
    await callVoidApi(`user/SetIncludeProperNames?includeProperNames=${includeProperNames}`, 'PUT', accessToken);
}

export const putPlaySounds = async (accessToken, playSounds) => {
    await callVoidApi(`user/SetPlaySounds?playSounds=${playSounds}`, 'PUT', accessToken);
}

export const resetLearning = async (accessToken, confirmation) => {
    return await callApi(`user/ResetLearning?confirmation=${confirmation}`, 'POST', accessToken);
}

export const sendMessage = async (accessToken, messageObject) => {
    return await callApi(`user/SendMessage`, 'POST', accessToken, messageObject);
}

export const setDbLanguage = async (accessToken, language) => {
    await callVoidApi(`user/SetLanguage?language=${language}`, 'PUT', accessToken);
}