import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';

const auth0Domain = process.env.REACT_APP_AUTH_0_DOMAIN;
const auth0ClientId = process.env.REACT_APP_AUTH_0_CLIENT_ID;

const container = document.querySelector('#root');
const root = createRoot(container);
root.render(
    <Auth0Provider
        domain={auth0Domain}
        clientId={auth0ClientId}
        redirectUri={window.location.origin}
        useRefreshTokens={true}
        cacheLocation={'localstorage'}
        audience={'greek-study-api'}>
        <App/>
    </Auth0Provider>
);