
export const apiUrl = process.env.NODE_ENV !== 'development' ? `${window.location.origin}/api` : 'http://localhost:8081';

export const isSuccessStatusCode = (statusCode) => statusCode >= 200 && statusCode <= 299;

export const callApi = async (urlPath, method, accessToken, body, contentType) => {
    const params = { 
        crossDomain:true,
        method: method, 
        headers: new Headers({
            'Authorization':`Bearer ${accessToken}`,
            'Content-Type': contentType ?? 'application/json'
        })
    }

    if (body) {
        params.body = JSON.stringify(body);
    }

    const response = await fetch(`${apiUrl}/${urlPath}`, params);
    if (isSuccessStatusCode(response.status)) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
            return await response.json();
        }
    }

    return null;
}

export const callVoidApi = async (urlPath, method, accessToken, body) => {
    const params = { 
        crossDomain:true,
        method: method, 
        headers: new Headers({
            'Authorization':`Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        })
    }

    if (body) {
        params.body = JSON.stringify(body);
    }

    await fetch(`${apiUrl}/${urlPath}`, params);
}

export const callFileApi = async (urlPath, method, accessToken, body) => {
    const params = { 
        crossDomain:true,
        method: method, 
        headers: new Headers({
            'Authorization':`Bearer ${accessToken}`
        }),
        body: body
    }

    const response = await fetch(`${apiUrl}/${urlPath}`, params);
    if (isSuccessStatusCode(response.status)) {
        const json = await response.json();
        return json;
    }
    else {
        return null;
    }
}